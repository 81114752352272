<template>
	<v-form class="mt-2">
		<v-layout column>
			<v-flex shrink>
				<w-image-input
					accept="image/gif,image/jpeg,image/png,image/svg+xml"
					:active-color="value.primary_color"
					:class="`application theme--${theme}`"
					:label="$t('customization.image_input_label')"
					:readonly="readonly"
					style="margin: auto; min-height: 200px; height: 200px; width: 80%"
					:value="file"
					@input="onImageInput"
				/>
			</v-flex>
			<v-flex py-2 shrink>
				<v-layout justify-center row wrap>
					<w-color-picker class="mx-2" :label="$t('customization.primary_color')" :readonly="readonly" :value="copy.primary_color" @input="onColorInput($event, 'primary_color')" />
					<w-color-picker
						class="mx-2"
						:label="$t('customization.secondary_color')"
						:readonly="readonly"
						:value="copy.secondary_color"
						@input="onColorInput($event, 'secondary_color')"
					/>
				</v-layout>
			</v-flex>
			<v-flex v-if="isSavable && !readonly" mx-auto>
				<w-btn-save :color="value.primary_color" :disabled="!canSave" :loading="loading" @click="save()">{{ $t('actions.save') }}</w-btn-save>
			</v-flex>
		</v-layout>
	</v-form>
</template>

<script>
import Vue from 'vue'

export default {
	name: 'WhiteLabelThemeForm',
	props: {
		readonly: {
			default: false,
			required: false,
			type: Boolean
		},
		theme: {
			required: true,
			type: String
		},
		validation: {
			default: false,
			required: false,
			type: Boolean
		},
		value: {
			default: () => ({}),
			required: false,
			type: Object
		}
	},
	data: function () {
		return {
			canSave: false,
			copy: {},
			file: null,
			isSavable: false,
			loading: false
		}
	},
	watch: {
		value: {
			deep: true,
			handler: 'copyValue',
			immediate: true
		}
	},
	created: function () {
		new Promise(resolve => {
			if (this.$listeners.hasOwnProperty('save') && this.$listeners.save) {
				this.isSavable = true
			}
			resolve()
		})
	},
	methods: {
		/**
		 * @return {void}
		 */
		computeValidation: function () {
			const isValid = (typeof this.value.logo !== 'undefined')
				&& (typeof this.value.primary_color !== 'undefined')
				&& (typeof this.value.secondary_color !== 'undefined')
			this.$emit('update:validation', isValid)
		},
		/**
		 * @param {object} value
		 *
		 * @return {Promise<void>}
		 */
		copyValue: function (value) {
			return new Promise(resolve => {
				Object.entries(value).forEach(([key, value]) => {
					if (key === 'logo') {
						this.file = value
					} else if (this.copy.hasOwnProperty(key)) {
						this.copy[key] = value
					} else {
						Vue.set(this.copy, key, value)
					}
				})
				this.canSave = false
				this.computeValidation()
				resolve()
			})
		},
		/**
		 * @param {Object} themeData
		 */
		emitInput: function (themeData) {
			this.$emit('input', themeData)
			this.computeValidation()
		},
		/**
		 * @param {string} color
		 * @param {string} field
		 *
		 * @return {Promise<void>}
		 */
		onColorInput: function (color, field) {
			return new Promise(resolve => {
				if (this.copy.hasOwnProperty(field)) {
					this.copy[field] = color
				} else {
					Vue.set(this.copy, field, color)
				}
				if (!this.isSavable) {
					const themeData = { ...this.value }
					themeData[field] = color
					this.emitInput(themeData)
				} else {
					this.canSave = this.value.id && this.value[field] !== this.copy[field]
				}

				resolve()
			})
		},
		/**
		 * @param {File} image
		 *
		 * @return {Promise<void>}
		 */
		onImageInput: function (image) {
			return new Promise(resolve => {
				this.file = image
				if (!this.isSavable) {
					const themeData = { ...this.value }
					themeData.logo = image
					this.emitInput(themeData)
				} else {
					this.canSave = !!this.value.id
				}

				resolve()
			})
		},
		save: function () {
			this.loading = true

			this.$listeners
				.save({
					...this.copy,
					logo: this.file
				})
				.finally(() => {
					this.loading = false
				})
		}
	}
}
</script>
